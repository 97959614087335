
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap">
                <label className={this.props.error ? 'required-label' : null}>{this.props.label} {this.props.error && this.props.translate ? <span>({this.props.translate("obavezno polje")})</span> : ''}</label>
                <input id={this.props.id} className={this.props.error ? 'required' : null} type={this.props.type} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} />
            </div>
        );
    }
}

export default Text;