import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';



const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            {...input}
        />
    )


const loginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

            

            <Field
                name="email"
                type="email"
                component={renderTextField}
                label={props.translate('E-Mail Adresa')}
                placeholder=""
            ></Field>

             <Field
                name="password"
                type="password"
                component={renderTextField}
                label={props.translate('Lozinka')}
                placeholder=""
            ></Field>


           {/* <Link to='/forget-password' className="lost-password"><p>{props.translate('Zaboravljna lozinka?')}</p></Link>*/}

            <button type="submit" className="button">{props.translate('PRIJAVI SE')}</button>


        </form>

    )
}

export default reduxForm({
    form: 'loginForm'  // a unique identifier for this form
})(loginForm)
