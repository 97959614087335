import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/images/no-image.jpg';

export class ListArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <Link to={`/product/${this.props.alias}/${this.props._id}`} className="list-article">
                <article >
                    <img onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.props.image ? this.props.image : image : image} />

                    <div>
                    {this.props.discount ?
                        <div className="discount">{this.props.discount}%</div>
                        :
                        null
                    }

                        <h6>{this.props.title}</h6>
                        <p>
                            {this.props.package && this.props.package[0] ? <span className="price">{this.props.package[0].price.formatPrice(this.props.currency)} / {this.props.package[0].name}</span> : null}
                            {this.props.inStock ? <span className="in-stock">
                                {this.props.translate('Dostupno')}
                            </span> : null}
                        </p>
                    </div>
                </article>
            </Link>

        )
    }
}

export default ListArticle;